


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Action } from 'vuex-class'
import { ConfirmRegistrationCredentials } from '@/store/user/types'
import { SimpleServerResponse } from '@/store/types'
import { DOWNLOAD_DB_TABLES_CSV } from '@/store/evaluation/actions'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
@Component({
  components: { SubmitSuccess, LoadingSpinner }
})
export default class DownloadCsv extends Vue {
  @Action(DOWNLOAD_DB_TABLES_CSV)
  getZipFile: (dto: ConfirmRegistrationCredentials) => Promise<SimpleServerResponse>

  private nonce: string | null = null
  private fileId: string | null = null
  private message = ''
  private info = ''
  private showError = false
  private loading = true

  loadFile (): void {
    this.loading = true
    this.showError = false

    if (this.nonce && this.fileId) {
      this.getZipFile({ nonce: this.nonce, id: this.fileId }).then(() => {
        this.message = this.$t('evaluation.download-success').toString()
      }).catch(() => {
        this.message = this.$t('evaluation.could-not-start-download').toString()
        this.showError = true
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.loading = false
      this.showError = true
      this.message = this.$t('evaluation.download-impossible').toString()
      this.info = this.$t('evaluation.link-corrupt').toString()
    }
  }

  created (): void {
    this.nonce = this.$route.params.nonce
    this.fileId = this.$route.params.fileId

    this.loadFile()
  }
}
